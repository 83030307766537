import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, media, Row } from 'styled-bootstrap-grid';
import { Collapse } from 'react-collapse';
import { animated, useTransition } from 'react-spring';
import Carousel from 'react-multi-carousel';
import { useBreakpoint, useTheme } from 'src/hooks';
import { SliceContainer, SlideDot as Dot, Title } from 'components/Shared';

const StyledImage = styled('img')`
  position: relative;
  z-index: 1;
  border-radius: ${({ theme }) => theme.borders.radius};
  width: 100%;
  display: flex;
  box-shadow: ${({ theme }) => theme.shadows.image};
  margin: 0;
  align-items: stretch;
`;

const AccordionContainer = styled.div`
  .ReactCollapse--collapse {
    transition: height 250ms;
  }
`;

const AccordionItem = styled.div`
  position: relative;
  padding-left: 0px;
  padding-top: 16px;
  margin-bottom: 16px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 4px;
    width: 50%;
    background: ${({ theme }) => theme.palette.grey[400]};
    border-radius: calc(${({ theme }) => theme.borders.radius} / 2);
  }

  &:after {
    content: ${({ active }) => active && '""'};
    position: absolute;
    left: 0;
    top: 0;
    height: 4px;
    width: 100%;
    background: ${({ theme }) => theme.palette.primary.main};
    border-radius: calc(${({ theme }) => theme.borders.radius} / 2);
  }
`;

const ItemTitle = styled.h6`
  cursor: pointer;
  color: ${({ active, theme }) =>
    active ? theme.palette.text.primary : theme.palette.grey[600]};
  margin-bottom: 8px;
`;

const PreloadImage = styled('img')`
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
`;

const SpacerImage = styled('img')`
  width: 100%;
  display: flex;
  opacity: 0;
`;

const MobileImage = styled('img')`
  width: 100%;
  display: flex;
  opacity: 1;
`;

const ImageContainer = styled.div`
  margin: 0px 0 32px 0;
  padding: 0px 0px 16px 0px;

  ${media.lg`
    margin: 0;
  `}
`;

const ImageTransition = styled(animated.div)`
  position: absolute;
  width: calc(100% - 32px);

  ${media.lg`
    width: auto;
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 32px;
`;

const ImageAccordionSlice = ({ data, sectionId }) => {
  const { title, description, items } = data;
  const [activeIndex, setActiveIndex] = useState(0);
  const { image: { url } = {} } = items[activeIndex];
  const { breakpoints } = useTheme();
  const showCarousel = useBreakpoint('lg');
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: breakpoints.lg },
      items: 1,
    },
    mobile: {
      breakpoint: { max: breakpoints.lg, min: 0 },
      items: 1,
    },
  };

  const transition = useTransition([activeIndex], {
    from: { opacity: 0, position: 'absolute' },
    enter: { opacity: 1, position: 'absolute', width: '100%' },
    leave: { opacity: 0, position: 'absolute' },
  });

  const fragment = transition((style) => {
    return (
      <ImageTransition>
        <StyledImage
          className="lazyload"
          src={url}
          sizes="80vw"
          attributeConfig={{
            src: 'data-src',
            srcSet: 'data-srcset',
            sizes: 'data-sizes',
          }}
        />
      </ImageTransition>
    );
  });

  return (
    <SliceContainer sectionId={sectionId}>
      <Title>{title}</Title>
      <Row>
        <Col col={12} lg={7} hiddenLgDown>
          <ImageContainer>
            {fragment}
            <SpacerImage
              className="lazyload"
              src={url}
              sizes="80vw"
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
            />
          </ImageContainer>
        </Col>
        <Col col={12} lg={5} hiddenLgUp>
          {showCarousel && (
            <Carousel
              afterChange={(previousSlide, { currentSlide }) => {
                setActiveIndex(currentSlide);
              }}
              removeArrowOnDeviceType={['tablet', 'mobile']}
              responsive={responsive}
              ssr
              transitionDuration={200}
              showDots={true}
              customDot={<Dot childCount={items.length} />}
            >
              {items.map(({ title, description, image }, index) => {
                const active = index === activeIndex;

                return (
                  <div>
                    <ImageContainer>
                      <MobileImage
                        className="lazyload"
                        src={image.url}
                        sizes="80vw"
                        attributeConfig={{
                          src: 'data-src',
                          srcSet: 'data-srcset',
                          sizes: 'data-sizes',
                        }}
                      />
                    </ImageContainer>
                    <ItemTitle active={active}>{title}</ItemTitle>
                    <p dangerouslySetInnerHTML={{ __html: description }} />
                  </div>
                );
              })}
            </Carousel>
          )}
        </Col>
        <Col col={12} lg={5} hiddenLgDown>
          <Content>
            <AccordionContainer>
              {items.map(({ title, description, image }, index) => {
                const active = index === activeIndex;

                return (
                  <AccordionItem key={index} active={active}>
                    <ItemTitle
                      onClick={() => setActiveIndex(index)}
                      active={active}
                    >
                      {title}
                    </ItemTitle>

                    <Collapse isOpened={active}>
                      <p dangerouslySetInnerHTML={{ __html: description }} />
                    </Collapse>
                    <PreloadImage
                      className="lazyload"
                      src={image.url}
                      sizes="80vw"
                      attributeConfig={{
                        src: 'data-src',
                        srcSet: 'data-srcset',
                        sizes: 'data-sizes',
                      }}
                    />
                  </AccordionItem>
                );
              })}
            </AccordionContainer>
          </Content>
        </Col>
      </Row>
    </SliceContainer>
  );
};

export default ImageAccordionSlice;
