import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Col, media, Container, Row } from 'styled-bootstrap-grid';
import { SliceContainer } from 'components/Shared';
import { PersonFill, EnvelopeFill } from '@styled-icons/bootstrap';
import { useForm } from 'react-hook-form';
import { useIntl } from 'gatsby-plugin-intl';
import sendLeadSignupMails from '../../utils/semplates';

const SignupContainer = styled.div`
  position: relative;
  z-index: 1;
  background: ${({ theme }) => theme.palette.secondary.main};
  color: #fff;
  border-radius: ${({ theme }) => theme.borders.radius};
  min-height: 300px;
  text-align: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px;

  ${media.lg`
    padding: 32px 32px;
    text-align: left;
  `}
`;

const Headline = styled.h2`
  font-size: 32px;
  margin: 16px 0px 32px 0px;

  ${media.lg`
  font-size: 36px;
  margin: 32px 0px 48px 0px;
  `}
`;

const SuccessMessage = styled.h4``;

const ColumnContainer = styled.div`
  background: white;
  width: 100%;
  height: 100%;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: no-wrap;
  border-bottom-width: 3px;
  border-bottom-style: ${(props) => (props.error ? 'solid' : 'none')};
  border-bottom-color: ${({ theme }) => theme.palette.warning.main};
`;

const ButtonContainer = styled.div`
  background: white;
  width: 100%;
  height: 100%;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: no-wrap;
`;

const NameContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: no-wrap;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const StyledInput = styled.input`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-grow: 1;
  padding-left: 16px;
  width: 90%;
  height: 48px;
  border: none;
  text-align: left;
  border-radius: ${({ theme }) => theme.borders.radius};
  outline: none !important;
  color: ${(props) => (props.error ? 'red' : 'none')};
`;

const Icon = styled.div`
  padding-left: 24px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const StyledSubmitButton = styled.input`
  width: 100%;
  color: white;
  border: 1px solid;
  border-color: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.125s ease-out;
  white-space: nowrap;
  font-weight: 400;
  text-decoration: none;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.palette.grey[700] : theme.palette.primary.main};
  padding: ${({ size }) => (size === 'small' ? '10px 14px' : '12px 16px')};
  font-size: ${({ size }) => (size === 'small' ? '14px' : '16px')};
  border-radius: ${({ theme }) => theme.borders.radius};

  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.palette.grey[700] : theme.palette.primary.dark};
  }
`;

const LeadSignup = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const intl = useIntl();
  const [mailSent, setMailSent] = useState(false);

  const translate = (id) => {
    return intl.formatMessage({ id });
  };

  const onSubmit = async (data) => {
    const success = await sendLeadSignupMails(
      data['mail'],
      data['firstName'],
      data['lastName'],
      intl['locale']
    );
    success && setMailSent(true);
  };

  return (
    <SliceContainer>
      <Row>
        <Col md={12} lg={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SignupContainer>
              <Container>
                <Row alignItems="center" justifyContent="center">
                  <Headline>
                    {translate('Slices.LeadSignupSlice.title')}
                  </Headline>
                </Row>
                <Row justifyContent="center" alignItems="center">
                  {!mailSent ? (
                    <Fragment>
                      <Col md={12} lg={12} xl={6}>
                        <ColumnContainer
                          error={errors.firstName || errors.lastName}
                        >
                          <Icon>
                            <PersonFill size={20} />
                          </Icon>
                          <NameContainer>
                            <StyledInput
                              error={errors.firstName}
                              placeholder={translate(
                                'Slices.LeadSignupSlice.firstName'
                              )}
                              {...register('firstName', {
                                required: true,
                                pattern: /^[A-Za-z]+$/i,
                              })}
                            />
                            <StyledInput
                              error={errors.lastName}
                              placeholder={translate(
                                'Slices.LeadSignupSlice.lastName'
                              )}
                              {...register('lastName', {
                                required: true,
                                pattern: /^[A-Za-z]+$/i,
                              })}
                            />
                          </NameContainer>
                        </ColumnContainer>
                      </Col>
                      <Col md={12} lg={9} xl={4}>
                        <ColumnContainer error={errors.mail}>
                          <Icon>
                            <EnvelopeFill size={20} />
                          </Icon>
                          <StyledInput
                            placeholder={translate(
                              'Slices.LeadSignupSlice.mailPlaceholder'
                            )}
                            error={errors.mail}
                            {...register('mail', {
                              required: true,
                              pattern: /^\S+@\S+\.\S+$/i,
                            })}
                          />
                        </ColumnContainer>
                      </Col>
                      <Col md={3} xl={2}>
                        <ButtonContainer>
                          <StyledSubmitButton
                            disabled={
                              errors.firstName || errors.lastName || errors.mail
                            }
                            type="submit"
                            value={translate(
                              'Slices.LeadSignupSlice.buttonText'
                            )}
                          />
                        </ButtonContainer>
                      </Col>
                    </Fragment>
                  ) : (
                    <SuccessMessage>
                      {translate('Slices.LeadSignupSlice.successMessage')}
                    </SuccessMessage>
                  )}
                </Row>
              </Container>
            </SignupContainer>
          </form>
        </Col>
      </Row>
    </SliceContainer>
  );
};

export default LeadSignup;
