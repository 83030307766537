import React from 'react';
import styled from 'styled-components';
import { Container as Grid, media } from 'styled-bootstrap-grid';
import { BodyLarge, Highlight, SlideDot as Dot } from 'components/Shared';
import Carousel from 'react-multi-carousel';
import { useTheme } from 'src/hooks';
import { Link } from 'gatsby-plugin-intl';

const Container = styled.section`
  position: relative;
  ${({ theme }) => `
    background: ${theme.palette.primary.main};
  `}
  padding: 32px 0;

  .react-multi-carousel-list {
    margin: 0 -16px;
    padding-bottom: 24px;
  }

  .card-item {
    padding: 0 16px;
  }

  ${media.lg`
    padding: 60px 0 100px;
  `}
`;

const Headline = styled.h2`
  text-align: center;
  margin-bottom: 64px;
  color: white;
`;

const ItemContainer = styled.div`
  position: relative;
  z-index: 2;
  border-radius: ${({ theme }) => theme.borders.radius};
  background: #fff;
  height: 100%;
  overflow: hidden;
  transform: translateY(0);
  box-shadow: 0px 34px 44px 0px rgba(0, 0, 0, 0);
  transition: all 0.4s ease-out;

  ${media.lg`
    max-width: 30vW;
    
    &:hover {
      box-shadow: ${({ theme }) => theme.shadows.card};
    }
  `}
`;

const ItemBody = styled.div`
  padding: 16px;

  ${media.lg`
    padding: 40px;
  `}
`;

const ItemHeadline = styled(Link)`
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 16px;
  text-decoration: none;
  color: black;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const StyledImage = styled('img')`
  height: 200px;
  width: 100%;
  object-fit: cover;

  ${media.lg`
    min-height: 260px;
  `}
`;

const Card = ({ image, title, description, highlight }) => {
  return (
    <ItemContainer>
      <StyledImage
        className="lazyload"
        src={image.url}
        sizes="30vw"
        attributeConfig={{
          src: 'data-src',
          srcSet: 'data-srcset',
          sizes: 'data-sizes',
        }}
      />
      <ItemBody>
        <Highlight>{highlight}</Highlight>
        <ItemHeadline to={'/projects'}>{title}</ItemHeadline>
        <BodyLarge dangerouslySetInnerHTML={{ __html: description }} />
      </ItemBody>
    </ItemContainer>
  );
};

const CarouselSlice = ({ data: { title, items } }) => {
  const { breakpoints } = useTheme();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: breakpoints.lg },
      items: 1,
    },
    mobile: {
      breakpoint: { max: breakpoints.lg, min: 0 },
      items: 1,
    },
  };

  return (
    <Container>
      <Grid>
        <Headline>{title}</Headline>
        <div style={{ marginTop: '64px' }}>
          <Carousel
            removeArrowOnDeviceType={['tablet', 'mobile']}
            responsive={responsive}
            ssr
            transitionDuration={200}
            showDots={true}
            customDot={<Dot />}
            itemClass="card-item"
          >
            {items.map((card) => {
              return <Card {...card} key={card.title} />;
            })}
          </Carousel>
        </div>
      </Grid>
    </Container>
  );
};

export default CarouselSlice;
