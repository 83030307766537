import React from 'react';
import { Col, media, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { BodyLarge, SliceContainer } from 'components/Shared';

const Image = styled('img')`
  width: 100%;
  height: 240px;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.shadows.card};

  ${media.lg`
    height: 480px;
  `}
`;

const Text = styled.div`
  padding: 32px 0;
`;

const StyledRectangle = styled.div`
  position: absolute;
  width: 400px;
  bottom: -100px;
  left: 0;
  height: 500px;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

const TextAndImageLeftSlice = ({
  data: { image, title, description },
  first,
}) => {
  return (
    <SliceContainer first={first}>
      <StyledRectangle />
      <Row>
        <Col col={12} lg={6} lgOffset={1}>
          <Image
            className="lazyload"
            src={image.url}
            sizes="100vw"
            attributeConfig={{
              src: 'data-src',
              srcSet: 'data-srcset',
              sizes: 'data-sizes',
            }}
          />
        </Col>
        <Col col={12} lg={4.75} lgOffset={0.25}>
          <Text>
            <h3>{title}</h3>
            <BodyLarge dangerouslySetInnerHTML={{ __html: description }} />
          </Text>
        </Col>
      </Row>
    </SliceContainer>
  );
};

export default TextAndImageLeftSlice;
