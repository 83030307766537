import * as axios from 'axios';

const CUSTOMER_MAIL_TEMPLATE = 'DEMO_SIGNUP_CUSTOMER_MAIL';
const CUSTOMER_MAIL_TEMPLATE_EN = 'DEMO_SIGNUP_CUSTOMER_MAIL_ENG';
const NOTIFICATION_MAIL_TEMPLATE = 'DEMO_SIGNUP_NOTIFICATION_MAIL';
const NOTIFICATION_MAIL_TEMPLATE_EN = 'DEMO_SIGNUP_NOTIFICATION_MAIL_ENG';

const sendCustomerMail = async (
  customerMail,
  customerFirstName,
  customerLastName,
  lang
) => {
  const templateName =
    lang === 'de' ? CUSTOMER_MAIL_TEMPLATE : CUSTOMER_MAIL_TEMPLATE_EN;
  try {
    const { data } = await axios({
      method: 'POST',
      url: `https://api.semplates.io/send/${templateName}?api_key=${process.env.GATSBY_SEMPLATES_API_KEY}`,
      data: {
        recipients: [customerMail],
        variables: {
          CUSTOMER_FIRST_NAME: customerFirstName,
          CUSTOMER_LAST_NAME: customerLastName,
        },
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

const sendNotificationMail = async (
  customerMail,
  customerFirstName,
  customerLastName,
  lang
) => {
  const templateName =
    lang === 'de' ? NOTIFICATION_MAIL_TEMPLATE : NOTIFICATION_MAIL_TEMPLATE_EN;
  try {
    const { data } = await axios({
      method: 'POST',
      url: `https://api.semplates.io/send/${templateName}?api_key=${process.env.GATSBY_SEMPLATES_API_KEY}`,
      data: {
        recipients: [process.env.GATSBY_SEMPLATES_NOTIFICATION_MAIL_ADDRESS],
        variables: {
          CUSTOMER_FIRST_NAME: customerFirstName,
          CUSTOMER_LAST_NAME: customerLastName,
          CUSTOMER_EMAIL: customerMail,
        },
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

const sendLeadSignupMails = async (
  customerMail,
  customerFirstName,
  customerLastName,
  lang
) => {
  const [notificationResult, customerResult] = await Promise.all([
    sendNotificationMail(
      customerMail,
      customerFirstName,
      customerLastName,
      lang
    ),
    sendCustomerMail(customerMail, customerFirstName, customerLastName, lang),
  ]);

  const success = notificationResult && customerResult ? 1 : 0;
  return success;
};

export default sendLeadSignupMails;
