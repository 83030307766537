import React from 'react';
import styled from 'styled-components';
import { OutlinedButton, SliceContainer, Title } from '../Shared';
import { useIntl } from 'gatsby-plugin-intl';
import attachmentArray from '../../../static/attachments';

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const CardContainer = styled.div`
  width: 30%;
  min-height: 500px;
  padding: 0px 0px 0px 0px;
  @media (max-width: 992px) {
    width: 100%;
    min-height: 300px;
  }
`;

const Card = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 32px;
  margin-bottom: 32px;
  background: ${({ theme, color }) => theme.palette[color].main};
  justify-content: center;
  text-align: center;
  box-shadow: ${({ theme }) => theme.shadows.card};
  transform: translateY(0px);
  transition: transform 0.25s ease-in;
  @media (max-width: 992px) {
    &:hover {
      transform: translateY(-20px);
    }
  }
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 64px;
  overflow-wrap: break-word;
`;

const CardHeaderTitle = styled.h5`
  padding: 0px;
  margin: 0px;
  font-weight: 500;
  color: white;
  width: auto;
  text-align: center;
  word-break: break-word;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 2;
`;

const CardText = styled.p`
  color: white;
  text-align: left;
`;

const CardFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

const ActionArea = styled.div`
  height: 32px;
`;

const CardIconContainer = styled.div`
  width: 100%;
  height: 100px;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const CardIconBackground = styled.div`
  padding: 10px;
  background: white;
  width: 50px;
  height: 50px;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
`;

const CardIcon = styled.div`
  color: ${({ theme, color }) => theme.palette[color].main};
`;

const AttachementsSlice = ({ data: { title, items }, sectionId }) => {
  items = items.slice(0, 3);

  const intl = useIntl();
  const downloadAttachment = (index) => {
    window.open(attachmentArray[intl.locale][index], '_blank');
  };

  return (
    <SliceContainer sectionId={sectionId}>
      <Title>{title}</Title>
      <CardsContainer>
        {items.map(({ title, description, image, color, button }, index) => {
          return (
            <CardContainer key={index}>
              <Card color={color}>
                <CardHeader>
                  <CardHeaderTitle>{title}</CardHeaderTitle>
                </CardHeader>
                <CardIconContainer>
                  <CardIconBackground>
                    <CardIcon color={color}>{image.url}</CardIcon>
                  </CardIconBackground>
                </CardIconContainer>
                <CardContent>
                  <CardText dangerouslySetInnerHTML={{ __html: description }} />
                </CardContent>

                <CardFooter>
                  <ActionArea>
                    <OutlinedButton
                      color="grey"
                      noBorder
                      onClick={() => downloadAttachment(index)}
                    >
                      {button}
                    </OutlinedButton>
                  </ActionArea>
                </CardFooter>
              </Card>
            </CardContainer>
          );
        })}
      </CardsContainer>
    </SliceContainer>
  );
};

export default AttachementsSlice;
