import { Col, media, Row } from 'styled-bootstrap-grid';
import { Collapse } from 'react-collapse';
import React from 'react';
import styled from 'styled-components';
import { ChevronDown } from '@styled-icons/feather/ChevronDown';

const StyledChevron = styled(ChevronDown)`
  transform: scaleY(1);
  transition: transform 0.25s linear;
  color: ${({ theme }) => theme.palette.primary.main};
  position: absolute;
  right: 0;
  top: 0;
  ${({ active }) => active && 'transform: scaleY(-1);'}
`;

const ItemContainer = styled(Row)`
  position: relative;
  margin: 16px 0 16px 0;
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: 8px;
  background-color: white;
  border-left: 8px solid ${({ theme }) => theme.palette.primary.main};

  ${media.lg`
    padding: 24px 16px 24px 32px;
  `}
`;

const Teaser = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

const AccordionDetailsContainer = styled(Row)`
  margin: 32px 0 0 0;
`;

const Image = styled('img')`
  height: 100%;
  widht: 100%;
`;

const HiddenLgUp = styled('i')`
  ${media.lg`
      display: none;
  `}
`;

const AccordionItem = ({
  data: { title, teaser, description, image },
  active,
  onChange,
}) => {
  return (
    <ItemContainer>
      <Col lg={5} style={{ position: 'relative' }} onClick={onChange}>
        <h5>{title}</h5>
        <HiddenLgUp>
          <StyledChevron size={30} active={active} />
        </HiddenLgUp>
      </Col>
      <Col lg={6} sm={12} lgOffset={0.5}>
        <Teaser>{teaser}</Teaser>
      </Col>
      <Col lg={0.5} hiddenLgDown onClick={onChange}>
        <StyledChevron size={30} active={active} />
      </Col>
      <Collapse isOpened={active}>
        <AccordionDetailsContainer>
          <Col lg={5}>{image && image.url && <img src={image.url} />}</Col>
          <Col lg={6} lgOffset={0.5}>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Col>
        </AccordionDetailsContainer>
      </Collapse>
    </ItemContainer>
  );
};

export default AccordionItem;
