import styled, { css } from 'styled-components';
import React from 'react';

const CardContainer = styled.div`
  margin-bottom: 32px;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  transition: z-index 500ms, transform 500ms;
  z-index: 0;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  transform-style: preserve-3d;
  &.flipped {
    z-index: 1;
  }

  @media (max-width: 767px) {
    min-height: 350px;
  }
`;

const CardInner = styled.div`
  flex: 1;
  display: flex;
  transition: transform 500ms;
  transform-style: preserve-3d;
  height: 340px;
  &.flipped {
    transform: rotateY(180deg);
  }

  @media only screen and (min-width: 990px) {
    height: 340px;
  }

  @media only screen and (min-width: 1200px) {
    height: 420px;
  }
`;

const CardSide = css`
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
`;

const CardFront = styled.div`
  ${CardSide}
  z-index: 0;
  background: white;
`;

const CardBack = styled.div`
  ${CardSide}
  transform: rotateY(-180deg) translate(100%, 0);
  z-index: 1;
`;

const FlipCard = ({ children }) => {
  const [flipped, setFlipped] = React.useState(false);

  return (
    <CardContainer
      onMouseEnter={() => setFlipped(true)}
      onMouseLeave={() => setFlipped(false)}
    >
      <CardInner className={flipped ? 'flipped' : ''}>
        <CardFront>{children[0]}</CardFront>
        <CardBack>{children[1]}</CardBack>
      </CardInner>
    </CardContainer>
  );
};

export default FlipCard;
