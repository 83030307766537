import React from 'react';
import automotive from './self-optimization.jpg';
import chemistry from './chemistry.jpg';
import city from './city.jpg';
import cockpit from './cockpit.png';
import construction from './underConstruction.jpg';
import compass from './compass.jpg';
import contract from './contract.jpg';
import consumerGoods from './captive-strategy.jpg';
import dataDashboard from './data-dashboard.jpg';
import energy from './energy.jpg';
import faqAdditionalWork from './faq-additional-work.jpg';
import faqBestData from './faq-best-data.jpg';
import faqDataSecurity from './faq-data-security.jpg';
import faqIbnr from './faq-ibnr.jpg';
import faqInfaltion from './faq-inflation.jpg';
import faqConsolidation from './faq-consolidation.jpg';
import faqSimultion from './faq-simulation.jpg';
import faqVar from './faq-var.jpg';
import pharma from './pharma-risk.jpg';
import practitioner from './practitioners.jpg';
import riskprofiler from './riskprofiler.png';
import risks from './risks.jpg';
import simulation from './simulation.png';
import toolbox from './toolbox.png';

import { Key } from '@styled-icons/entypo/Key';
import { Security } from '@styled-icons/material/Security';
import { Transfer } from '@styled-icons/boxicons-regular/Transfer';
import { Certification } from '@styled-icons/boxicons-regular/Certification';

const securityIcons = [
  <Key size={30} />,
  <Certification size={30} />,
  <Security size={30} />,
  <Transfer size={30} />,
];

const firstImages = [cockpit, toolbox, riskprofiler, simulation];
const projectImages = [
  automotive,
  chemistry,
  consumerGoods,
  energy,
  city,
  pharma,
];
const aboutImages = [practitioner, dataDashboard, compass, risks];

const faqImages = [
  faqBestData,
  faqInfaltion,
  faqIbnr,
  faqVar,
  faqDataSecurity,
  faqConsolidation,
  faqSimultion,
  faqBestData,
  faqAdditionalWork,
  faqSimultion,
];
export {
  aboutImages,
  firstImages,
  projectImages,
  securityIcons,
  construction,
  contract,
  faqImages,
};
