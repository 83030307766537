import attachment1 from './Riskeeper Download Selbstbehaltsoptimierung.pdf';
import attachment2 from './Riskeeper Download Captive Machbarkeitsstudie Auszug.pdf';
import attachment3 from './Riskeeper Download Optimierung des Versicherungsmanagements.pdf';
import isoAttachment from './Riskeeper AG ISO 27001 Certificate 2025.pdf';

const attachmentListDE = [attachment1, attachment2, attachment3];
const attachmentListEN = [attachment1, attachment2, attachment3];

const attachmentArray = {
  de: attachmentListDE,
  en: attachmentListEN,
  iso: isoAttachment,
};

export default attachmentArray;
