import React from 'react';
import { Col, media, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import {
  BodyLarge,
  Headline,
  SliceContainer,
  TextContainer,
} from 'components/Shared';
import { ButtonLink } from '../Shared/Buttons';

const Image = styled('img')`
  width: 100%;
  height: 240px;
  object-fit: ${({ objectFit }) => (objectFit ? objectFit : 'cover')};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.shadows.card};
  transform: translateY(0) translateX(0);
  transition: all 0.4s ease-out;
  margin-bottom: -6px;
  ${media.lg`
    height: 100%;
    max-height: 480px;
    
    &:hover { 
      box-shadow: ${({ theme }) => theme.shadows.card};
      transform: translateY(-12px) translateX(12px);
    }
  `}
`;

const ImageBackground = styled('div')`
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

const ButtonContainer = styled('div')`
  text-align: center;

  ${media.lg`
    text-align: left;
  `}
`;

const TextAndImageRightSlice = ({
  data: { image, title, description, button },
  first,
}) => {
  return (
    <SliceContainer first={first}>
      <Row>
        <Col col={12} lg={5}>
          <TextContainer>
            <Headline>{title}</Headline>
            <BodyLarge dangerouslySetInnerHTML={{ __html: description }} />
            {button && (
              <ButtonContainer>
                <ButtonLink to={'/products'}>{button}</ButtonLink>
              </ButtonContainer>
            )}
          </TextContainer>
        </Col>
        <Col lgOffset={0.25} col={12} lg={6.75}>
          <ImageBackground>
            <Image
              alt={image.alt}
              className="lazyload"
              src={image.url}
              sizes="100vw"
              objectFit={image.objectFit}
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
            />
          </ImageBackground>
        </Col>
      </Row>
    </SliceContainer>
  );
};

export default TextAndImageRightSlice;
