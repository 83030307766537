import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'styled-bootstrap-grid';
import {
  BodyLarge,
  SliceContainer,
  Title,
  SlideDot as Dot,
} from 'components/Shared';
import Carousel from 'react-multi-carousel';
import attachmentArray from '../../../static/attachments';
import { useTheme } from 'src/hooks';
import { Download } from '@styled-icons/boxicons-regular/Download';

const ItemIcon = styled.div`
  margin-right: 8px;
`;

const ItemContainer = styled.div`
  display: flex;
  padding: 0 32px 32px 0;
`;

const ItemAreaHeadline = styled.h4`
  padding-bottom: 32px;
`;

const ItemHeadline = styled.h5`
  margin-bottom: 8px;
`;

const Item = ({ icon, title = {}, description = {} }) => {
  const isIsoHeadline = title === 'ISO 27001 Zertifizierung';

  const downloadIsoAttachment = () => {
    const link = attachmentArray.iso;
    window.open(link, '_blank');
  };

  return (
    <ItemContainer>
      <ItemIcon>{icon}</ItemIcon>
      <div>
        <ItemHeadline>
          {title}
          {isIsoHeadline && (
            <Download
              size={24}
              onClick={downloadIsoAttachment}
              style={{ cursor: 'pointer', marginLeft: '8px' }}
              title="Download PDF"
            />
          )}
        </ItemHeadline>
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </ItemContainer>
  );
};

const Features2x2 = ({ data: { title, description, subtitle, items } }) => {
  items = items.slice(0, 4);
  const { breakpoints } = useTheme();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: breakpoints.lg },
      items: 1,
    },
    mobile: {
      breakpoint: { max: breakpoints.lg, min: 0 },
      items: 1,
    },
  };

  return (
    <SliceContainer>
      <Title>{title}</Title>
      <BodyLarge dangerouslySetInnerHTML={{ __html: description }} />
      <Row>
        <Col col={12} hiddenLgUp>
          <ItemAreaHeadline>{subtitle}</ItemAreaHeadline>
        </Col>
        {items
          .map(({ image, title, text }, index) => {
            return (
              <Col key={index} col={12} hiddenLgUp>
                <Item icon={image.url} title={title} description={text} />
              </Col>
            );
          })
          .filter((item) => !!item)}

        <Col lg={12} hiddenLgDown>
          <ItemAreaHeadline>{subtitle}</ItemAreaHeadline>
        </Col>
        <Col lg={12} hiddenLgDown>
          <Row>
            {items.map(({ image, title, text }, index) => {
              return (
                <Col key={`argument-${index}`} col={6}>
                  <Item icon={image.url} title={title} description={text} />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </SliceContainer>
  );
};

export default Features2x2;
