import React from 'react';
import { SliceContainer, Title } from '../Shared';
import FlipCard from '../Shared/FlipCard';
import styled from 'styled-components';
import { Col, Row } from 'styled-bootstrap-grid';

const Flips = styled(Row)`
  margin-bottom: 32px;

  h4 {
    font-size: 19px;
  }

  p {
    font-size: 14px;
  }

  @media only screen and (min-width: 1200px) {
    p {
      font-size: 16px;
    }

    h4 {
      font-size: 24px;
    }
  }

  .card {
    height: 200px;
  }
`;

const Front = styled.div`
  background-color: white;
  height: 100%;
  width: 100%;
`;

const Back = styled.div`
  background-color: white;
  height: 100%;
  width: 100%;
`;

const CardContent = styled.div`
  padding: 16px;
`;

const Image = styled.img`
  height: 66%;
  width: 100%;
  object-fit: cover;

  @media only screen and (max-width: 1200px) {
    height: 55%;
  }
`;

const FlipCardSlice = ({
  data: { title, solutionHeader, resultHeader, items },
  sectionId,
}) => {
  items = items.slice(0, 6);
  return (
    <SliceContainer sectionId={sectionId}>
      <Title>{title}</Title>
      <Flips>
        {items.map(
          ({ customerProfile, problem, solution, result, image }, index) => {
            return (
              <Col lg={4} md={6} key={`flip-card-${index}`}>
                <FlipCard>
                  <Front>
                    <Image src={image.url} />
                    <CardContent>
                      <h4>{customerProfile}</h4>
                      <p>{problem}</p>
                    </CardContent>
                  </Front>
                  <Back>
                    <CardContent>
                      <h5>{solutionHeader}</h5>
                      <p>{solution}</p>
                      <h5>{resultHeader}</h5>
                      <p>{result}</p>
                    </CardContent>
                  </Back>
                </FlipCard>
              </Col>
            );
          }
        )}
      </Flips>
    </SliceContainer>
  );
};

export default FlipCardSlice;
