import React, { Fragment, useState } from 'react';
import { Col, Row } from 'styled-bootstrap-grid';
import {
  BodyLarge,
  Headline,
  SliceContainer,
  TextContainer,
} from 'components/Shared';
import AccordionItem from '../Shared/AccordionItem';

const FAQSlice = ({ data: { title, description, items } }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  return (
    <SliceContainer first>
      <Row>
        <Col>
          <TextContainer>
            <Headline>{title}</Headline>
            <BodyLarge dangerouslySetInnerHTML={{ __html: description }} />
          </TextContainer>
        </Col>
      </Row>
      {items.map(({ question, answer, image }, index) => {
        const active = index === activeIndex;
        const cutterIndex = 120 + answer.substr(120, 120).indexOf(' ');
        return (
          <AccordionItem
            key={`accordion${index}`}
            data={{
              title: question,
              teaser: `${answer.slice(0, cutterIndex)} ...`,
              description: `...${answer.slice(cutterIndex, 2000)}`,
              image: image,
            }}
            active={active}
            onChange={() => setActiveIndex(active ? null : index)}
          />
        );
      })}
    </SliceContainer>
  );
};

export default FAQSlice;
